import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

/**
 * Service for platform options.
 * Need to be used when app is using objects such us window, localstorage, document...
 */
@Injectable({ providedIn: 'root' })
export class PlatformService {
  platformId = inject(PLATFORM_ID);

  /**
   * Returns whether a platform id represents a browser platform.
   */
  public isPlatformBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  /**
   * Returns whether a platform id represents a server platform.
   */
  public isPlatformServer(): boolean {
    return isPlatformServer(this.platformId);
  }
}
